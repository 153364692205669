
<template>
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <div class="heading">
      <h1 v-if="!communityId">Add Community</h1>
      <h1 v-if="communityId">Edit Community</h1></div>
  </el-col>
  <el-col :xs="6" :sm="6" :md="6" :lg="12" :xl="12">
    <div class="topsearch">
      <el-button type="primary" @click="backtolist()">Back To Community Pages </el-button>
    </div>
  </el-col>
 </el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
  <div v-if="showAlertAdded">
    <el-alert
      title="Community Added Successfully"
      type="success"
      effect="dark"
      v-if="!communityId"
    />
    <el-alert
        title="Community Updated Successfully"
        type="success"
        effect="dark"
        v-if="communityId"
    />
  </div>
  <div v-if="showAlertSlugexit">
    <el-alert
      title="The slug has already been taken."
      type="error"
      effect="dark"
    />
  </div>
  </el-col>
</el-row>
    <el-form
      ref="ruleForm"
      :model="data"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
      :label-position="labelPosition" 
    >
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
        label="Community Name"
        prop="community_name"
      >
       <el-input
          v-model="data.community_name"
          type="text"
          autocomplete="off"
        />
      </el-form-item>
    </el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
      label="Type"
      prop="community_type">
      <select name="community_type" v-model="data.community_type" class="selectstatus">
        <option v-for="item in selectcom_type" 
                :key="item.label"
                :value="item.value"
                :label="item.label"
        >
          {{item.label}}
        </option>
  </select>
  </el-form-item>
  </el-col>
</el-row>

<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
      label="Area"
      prop="state">
      <el-select v-model="data.state_name"  placeholder="Select Region / State">
        <el-option              
        v-for="item in states"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        @click="getCities(item.value)"
        />
      </el-select>
  </el-form-item>
  </el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
      label="Subarea"
      prop="city">
      <el-select v-model="data.city_name" placeholder="City">
              <el-option              
              v-for="item in cities"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              />
      </el-select>
  </el-form-item>
  </el-col>
</el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Community Zone"
        prop="community_group"
      >
      <el-select v-model="data.community_group">
      <el-option v-for="item in selectcom_group" 
              :key="item.label"
              :value="item.value"
              :label="item.label"
          >
          {{item.label}}
        </el-option>
      </el-select>
      </el-form-item>
  </el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Community Code"
        prop="community_code"
      >
      <el-input
          v-model="data.community_code"
          type="text"
          autocomplete="off"
        />
      </el-form-item>
  </el-col>
</el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Description"
        prop="description"
      >
       <TinyMce
              v-if="loadEditor"
              v-bind:content="data.description"
              @contentChanged="updateTextAreaValue"
          >
          </TinyMce>
      </el-form-item>
  </el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Footer Description"
        prop="footer_description"
      >
      <el-input
            v-model="data.footer_description"
            type="textarea"
          />
      </el-form-item>
  </el-col>
</el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Official Website Link"
        prop="official_website_link"
      >
     <el-input
          v-model="data.official_website_link"
          type="text"
          autocomplete="off"
        />
      </el-form-item>
  </el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Youtube Url"
        prop="youtube_url"
      >
     <el-input
          v-model="data.youtube_url"
          type="text"
          autocomplete="off"
        />
      </el-form-item>
  </el-col>
</el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Community Slug"
        prop="slug"
      >
     <el-input
          v-model="data.slug"
          type="text"
          autocomplete="off"
        />
      </el-form-item>
  </el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
  <el-form-item
  label="Meta Tag Title"
  prop="meta_title"
  >
  <el-input
            v-model="data.meta_title"
            type="textarea"
          />
  </el-form-item></el-col>
</el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">    
	  <el-form-item
          label="Community Logo"
          prop="community_logo"
        >
          <el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl"
            list-type="picture"
            :headers="imageUploadHeaders"
            name="file"
            :file-list="fileList"
            :limit="1"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleImageUploadSuccess"
            :on-error="handleImageUploadError"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em><br>
              JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
            </div>
          </el-upload>
        </el-form-item>
		<div v-if="showAlertImagetype"><el-alert title="The file must be a file of type: jpeg, png, jpg, gif, svg." type="error" effect="dark"/></div>
  </el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Meta Tag Keywords"
        prop="meta_keyword"
      >
      <el-input
            v-model="data.meta_keyword"
            type="textarea"
          />
      </el-form-item>
  </el-col>
</el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
   
	  <el-form-item
          label="Community Image"
          prop="community_image"
        >
          <el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl"
            list-type="picture"
            :headers="imageUploadHeaders"
            name="file"
            :file-list="fileList1"
            :limit="1"
            :on-preview="handlePreview1"
            :on-remove="handleRemove1"
            :on-success="handleImageUploadSuccess1"
            :on-error="handleImageUploadError1"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em><br>
              JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
            </div>
          </el-upload>
        </el-form-item>
		<div v-if="showAlertImage1type"><el-alert title="The file must be a file of type: jpeg, png, jpg, gif, svg." type="error" effect="dark"/></div>
  </el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <el-form-item
          label="Meta Tag Description"
          prop="meta_description"
        > 
        <el-input
            v-model="data.meta_description"
            type="textarea"
          />
        </el-form-item>
    </el-col>
</el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
 	  <el-form-item
          label="Map(JPEG)"
          prop="map_image"
        >
          <el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl"
            list-type="picture"
            :headers="imageUploadHeaders"
            name="file"
            :file-list="fileList2"
            :limit="1"
            :on-preview="handlePreview2"
            :on-remove="handleRemove2"
            :on-success="handleImageUploadSuccess2"
            :on-error="handleImageUploadError2"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em><br>
              JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
            </div>
          </el-upload>
        </el-form-item>
		<div v-if="showAlertImage2type"><el-alert title="The file must be a file of type: jpeg, png, jpg, gif, svg." type="error" effect="dark"/></div>
  </el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">

   <el-form-item 
           label="PDF File" 
            prop="pdf_link">
              <el-upload
                :action="imageUploadUrlPdf"
                :file-list="fileList3"
                :headers="imageUploadHeadersPdf"
                :limit="1"
                :on-error="handleImageUploadError3"
                :on-preview="handlePreview3"
                :on-remove="handleRemove3"
                :on-success="handleImageUploadSuccess3"
                class="upload-demo"
                drag
                list-type="text"
                name="file"
              >
                <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
              <template #tip>
                <div class="el-upload__tip">
                  PDF File only
                </div>
              </template>
              </el-upload>
            </el-form-item>
			<div v-if="showAlertPdftype"><el-alert title="The file must be a file of type: pdf." type="error" effect="dark"/></div>
    </el-col>	
</el-row>

<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
    label="Status"
    prop="status">
      <select name="status" v-model="data.status" class="selectstatus">
      <!--Add options when value is id-->
      <option v-for="item in selectstatus" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
      >
      {{item.label}}
      </option>
      </select>
    </el-form-item>
  </el-col>
</el-row>
<el-row :gutter="20" style="text-align: left;">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <el-form-item>
        <input
          id="communityId"
          v-model="communityId"
          name="communityId"
          type="hidden"
        >
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Submit
        </el-button>
        <el-button @click="resetForm('ruleForm')">
          Reset
        </el-button>
      </el-form-item>
  </el-col>
  <el-input
            v-model="data.extra_columns"
            type="hidden"
          />
</el-row>
    </el-form>
</template>
<script>
import CommunityService from "@/api/community.service";
import authHeader from '@/api/auth-header';
import TinyMce from "@/components/TinyMce";
import CountryService from "@/api/country.service";

  export default {
      components: {
      TinyMce
    },
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the community Title.'));
        } else {
          callback();
        }
      };
      var checkDescription = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the community Description.'));
        } else {
          callback();
        }
      };
      var checkSlug = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Slug.'));
        } else {
          callback();
        }
      };
      var checkMetatitle = (rule, value, callback) => {        
          callback();
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        showAlertPdftype: false,
        showAlertImagetype: false,
        showAlertImage1type: false,
        showAlertImage2type: false,
        labelPosition: 'top',
        communityId: this.$route.params.communityId,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadUrlPdf: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
        imageUploadHeadersPdf: null,
        states:[],
        cities:[],
		selectstatus:[{
          value: '1',
          label: 'Active'
        }, {
          value: '0',
          label: 'Inactive'
        }],
      selectcom_type:[{
          value: 'new',
          label: 'New'
        }, {
          value: 'old',
          label: 'Old'
        }, {
          value: 'presale',
          label: 'Presale'
        }],
      selectcom_group:[{
          value: 'South',
          label: 'South'
        }, {
          value: 'South West',
          label: 'South West'
        }, {
          value: 'West',
          label: 'West'
        },{
          value: 'South East',
          label: 'South East'
        },{
          value: 'North',
          label: 'North'
        },{
          value: 'North West',
          label: 'North West'
        },{
          value: 'North East',
          label: 'North East'
        },{
          value: 'East',
          label: 'East'
        }
      ],
      selectcom_state:[{
          value: '',
          label: 'All'
        }, {
          value: '35',
          label: 'Quebec'
        }, {
          value: '36',
          label: 'Alberta'
        }, {
          value: '37',
          label: 'Ontario'
        }
        ],
        selectcom_city:[{
          value: '',
          label: 'All'
        }, {
          value: '15809',
          label: 'Airdrie'
        }, {
          value: '5793',
          label: 'Banff'
        }, {
          value: '18056',
          label: 'Barrhead'
        }
        ],
        fileList: [],
        fileList1: [],
        fileList2: [],
        fileList3: [],
        data: {
          country:1,
          community_name: null,
          community_type:'new',
          community_group:null,
          state_name:null,
          state:null,
          city_name:null,
          city: null,
          footer_description:null,
          community_code:null,
          imageNew:null,
          imageNew1:null,
          imageNew2:null,
          imageNew3:null,
          pdf_link:null,
          official_website_link:null,
          description: null,
          slug: null,
          youtube_url:null,
          extra_columns:'test',
          meta_title: null,
          meta_description: null,
          meta_keyword: null,
          status: "1",
        
        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          community_name: [
            { validator: checkName, trigger: 'blur' }
          ],
          description: [
            { validator: checkDescription, trigger: 'blur' }
          ],
         
          slug: [
            { validator: checkSlug, trigger: 'blur' }
          ]
        }
      };
    },
    watch: {
    data: function () {
    if(!this.data.state_name && this.data.state) {
      this.data.state_name = this.extractNameById(this.data.state, this.states);
    }
    if(!this.data.city_name && this.data.city) {
      this.data.city_name = this.extractNameById(this.data.city, this.cities);
    }
    },
    states: function () {
      this.data.state_name = this.extractNameById(this.data.state, this.states);
    },
    cities: function () {
      this.data.city_name = this.extractNameById(this.data.city, this.cities);
    },
  },
	mounted() {
	this.setImageUploadUrl();
	this.setImageUploadUrl1();
	this.setImageUploadUrl2();
	this.setImageUploadUrl3();
    this.getStates(this.data.country);
    if(this.communityId){
      this.getdetail();
    }
  },
   computed: {
      loadEditor: function () {
        return !this.communityId || (this.communityId && this.data.description);
        },
  },
    methods: {
      async getCities(statsid){
        return CountryService.getCities(1,statsid).then(response => {
        this.cities=response.data.data;
      });
      },
      async getStates(countryid){
      return CountryService.getStates(1,countryid).then(response => {
      this.states=response.data.data;
      });
      },
      extractNameById(needle, hayStack) {
      let val = null;
      val = hayStack.filter(function(element){
      if(element['value'] == Number(needle)) {
      return element['label'];
      }
      });
      if(val.length) {
      return val[0]['label'];
      }
      return null;
      },

       updateTextAreaValue(value) {
        this.data.description = value;
      },   
      submitForm(formName) {
		this.showAlertAdded= false,
        this.showAlertSlugexit= false,
        this.showAlertPdftype= false,
        this.showAlertImagetype= false,
        this.showAlertImage1type= false,
        this.showAlertImage2type= false,
        this.data.state = this.data.state_name;
        this.data.city = this.data.city_name; 
        this.$refs[formName].validate((valid) => {
          if (valid && !this.communityId) {
             return CommunityService.add(this.data).then(response => {
				if(!response.data.errors){					
					this.showAlertAdded=true;				  
				}else{
					this.showAlertSlugexit=true;
					return false;
				}
				return this.$router.push("edit/"+response.data.communityid);
               // return response
              });
          } else if (valid && this.communityId) {
            return CommunityService.update(this.communityId, this.data).then(response => {
				if(!response.data.errors){					
					this.showAlertAdded=true;				  
				}else{
					this.showAlertSlugexit=true;
					return false;
				}

            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	backtolist(){
		this.$router.push("/community");
	},
	getdetail(){
    return CommunityService.get(this.communityId).then(response => {
        this.data = response.data.data;
		if(response.data.data.community_logo){
			this.fileList.push({name:'', url: response.data.data.community_logo_view});
			this.data.imageNew = response.data.data.community_logo;
		}
		if(response.data.data.community_image){
			this.fileList1.push({name:'', url: response.data.data.community_image_view});
			this.data.imageNew1 = response.data.data.community_image;
		}
		if(response.data.data.map_image){
			this.fileList2.push({name:'', url: response.data.data.map_image_view});
			this.data.imageNew2 = response.data.data.map_image;
		}
		if(response.data.data.pdf_link){
			this.fileList3.push({name:'', url: response.data.data.pdf_link_view});
			this.data.imageNew3 = response.data.data.pdf_link;
		}
        if(this.data.country){
          this.getStates(this.data.country);
        }
        if(this.data.state){
          this.getCities(this.data.state);
        }    
        return response;
      });
	},
   setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response, file, fileList) {
		  if(!response.errors){	
			this.showAlertImagetype=false;	
			this.data.imageNew = response.image_url;
		}else {
			this.fileList =[];
			this.showAlertImagetype=true;	
        }
      },
      handleImageUploadError(err, file, fileList) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
		  this.data.imageNew = null;
        console.log(file, fileList)
        //TODO: Delete the file
      },

	 setImageUploadUrl1() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess1(response, file, fileList1) {        
		if(!response.errors){	
			this.showAlertImage1type=false;	
			this.data.imageNew1 = response.image_url;
		}else {
			this.fileList1 =[];
			this.showAlertImage1type=true;	
        }
      },
      handleImageUploadError1(err, file, fileList1) {
        console.log(err)
      },
      handlePreview1(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove1(file, fileList1) {
		  this.data.imageNew1 = null;
        console.log(file, fileList1)
        //TODO: Delete the file
      },
	setImageUploadUrl2() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess2(response, file, fileList2) {
		 if(!response.errors){	
			this.showAlertImage2type=false;	
			this.data.imageNew2 = response.image_url;
		}else {
			this.fileList2 =[];
			this.showAlertImage2type=true;	
        }
      },
      handleImageUploadError2(err, file, fileList2) {
        console.log(err)
      },
      handlePreview2(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove2(file, fileList2) {
		  this.data.imageNew2 = null;
        console.log(file, fileList2)
        //TODO: Delete the file
      },
	setImageUploadUrl3() {
		this.imageUploadHeadersPdf = authHeader();
		this.imageUploadUrlPdf = process.env.VUE_APP_BASE_API + 'image-upload-pdf';
      },
      handleImageUploadSuccess3(response, file, fileList3) {
		 if(!response.errors){	
			this.showAlertPdftype=false;	
			this.data.imageNew3 = response.image_url;
		}else {
			this.fileList3 =[];
			this.showAlertPdftype=true;	
        }
      },
      handleImageUploadError3(err, file, fileList3) {
		  console.log(err)
      },
      handlePreview3(file) {
			
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove3(file, fileList3) {
		  this.data.imageNew3 = null;
        console.log(file, fileList3)
        //TODO: Delete the file
      },  
  }
}
</script>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
.el-select{
     width: 100%;
}
</style>